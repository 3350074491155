var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sign-in", class: { "no-padding": this.page === "unlock" } },
    [
      this.page !== "unlock"
        ? _c("div", { staticClass: "sign-in__header" }, [_vm._m(0), _vm._m(1)])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "sign-in__form",
          class: { "no-box-shadow": this.page === "unlock" },
        },
        [
          _vm.showForgotPass && !_vm.token
            ? _c("ForgotPass", {
                on: {
                  close: function ($event) {
                    ;(_vm.showForgotPass = false), (_vm.senha = "")
                  },
                },
              })
            : _vm.showChangePass
            ? _c("ChangePass")
            : _vm._e(),
          !_vm.showChangePass && !_vm.showForgotPass && !_vm.token
            ? _c(
                "form",
                {
                  staticClass: "sign-in__form__fieldset",
                  class: { "no-padding": this.page === "unlock" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.signIn.apply(null, arguments)
                    },
                  },
                },
                [
                  this.page !== "unlock"
                    ? _c(
                        "p",
                        { staticClass: "sign-in__form__fieldset__message" },
                        [
                          _c("span", [_vm._v("Olá!")]),
                          _vm._v(
                            " digite seu CPF ou CNPJ e senha para acessar seus\n        cartões.\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  this.page === "unlock"
                    ? _c(
                        "p",
                        {
                          staticClass: "sign-in__form__fieldset__message px-0",
                        },
                        [
                          _vm._v(
                            "\n        Olá! Você já tem cadastro na OnlyPay, para desbloquear seu novo cartão\n        digite seu login e senha nos campos abaixo.\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("v-textbox", {
                    attrs: {
                      "data-vv-as": "Documento",
                      label: "CPF ou CNPJ",
                      name: "email",
                      placeholder: "Digite seu CPF ou CNPJ",
                      mask: ["###.###.###-##", "##.###.###/####-##"],
                      type: "text",
                      validate: "required",
                    },
                    model: {
                      value: _vm.email,
                      callback: function ($$v) {
                        _vm.email = $$v
                      },
                      expression: "email",
                    },
                  }),
                  _c("v-textbox", {
                    attrs: {
                      id: "password-field",
                      "data-vv-as": "Senha",
                      label: "Senha",
                      name: "password.password",
                      placeholder: "Digite a sua Senha",
                      type: "password",
                    },
                    on: { input: _vm.checkPassword },
                    model: {
                      value: _vm.senha,
                      callback: function ($$v) {
                        _vm.senha = $$v
                      },
                      expression: "senha",
                    },
                  }),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("vue-recaptcha", {
                        attrs: { sitekey: _vm.site_key },
                        on: { verify: _vm.mxVerify },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "sign-in__form__fieldset__wrapper-submit" },
                    [
                      _c(
                        "div",
                        [
                          _c("v-button", {
                            staticClass:
                              "sign-in__form__fieldset__button-submit",
                            attrs: {
                              icon: "arrow-right",
                              bgColor: "blue",
                              radius: true,
                              rebranding: true,
                              label:
                                this.page === "unlock"
                                  ? "Desbloquear"
                                  : "Entrar",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a",
                        {
                          staticClass:
                            "sign-in__form__fieldset__forget-password",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showForgotPass = true
                            },
                          },
                        },
                        [_vm._v("\n          Esqueci a senha\n        ")]
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          this.page !== "unlock"
            ? _c("div", { staticClass: "sign-in__footer" }, [
                _vm._v("\n      © 2023 "),
                _c("a", { attrs: { href: "https://www.onlypay.com.br" } }, [
                  _vm._v("OnlyPay"),
                ]),
                _vm._v(" - Soluções\n      de pagamento\n    "),
              ])
            : _vm._e(),
        ],
        1
      ),
      this.page !== "unlock"
        ? _c("div", { staticClass: "sign-in__aside" }, [_vm._m(2), _vm._m(3)])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sign-in__header--small" }, [
      _c("img", {
        staticClass: "sign-in__header__logo",
        attrs: { src: require("@/assets/img/logo_white.svg") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "sign-in__header--large" }, [
      _c("img", {
        staticClass: "sign-in__header__logo",
        attrs: { src: require("@/assets/img/logo_white.svg") },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "sign-in__aside__text-1" }, [
      _vm._v("\n      Bem vindo à Only"),
      _c("strong", [_vm._v("Pay")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "sign-in__aside__text-2" }, [
      _vm._v("Simples "),
      _c("span", [_vm._v("assim!")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }